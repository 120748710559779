import IntroScreen from "../components/IntroScreen"
import ProjectCard from "../components/ProjectCard"
import CardRow from "../components/CardRow"
import Footer from "../components/Footer"

function Home(){
    return (
        <>
        <IntroScreen
            introBlurb="An undergraduate Biotechnology and Studio Arts student at the University of Toronto :) "
        />

        <CardRow>
            <div className="relative">
                <ProjectCard
                header = 'DESIGNLAB PROJECT'
                title = "GoodMarket"
                descTitle = "Online Grocery App"
                desc = "A solo project comprised of research, sitemapping, wireframing and design"

                projectLocation = "/goodmarket"
                imgLocation = '/assets/goodmarketCard.png'
                iconLocation = '/assets/goodmarketCardIcon.png'
                style = {{background: 'linear-gradient(180deg, #FFF0BC 11.87%, #DCF1A1 86.71%, #B4C979 105.76%)'}}
            />
                <div class="absolute w-[110%] lg:w-[110%] -top-[33%] xl:-top-[40%] 2xl:-top-[48%] -left-1/4 lg:-left-[30%] -z-10"><img src="/assets/backgroundcircle.svg"></img></div>
            </div>
            
            <ProjectCard
                header = 'HACK WESTERN 7 - AWARD WINNER'
                title = "Certi-Chain"
                descTitle = "Blockchain Verification Webapp"
                desc = "Collborated on the UI design for our product authentication webapp"

                projectLocation = "/certichain"
                imgLocation = '/assets/certichainCard.png'
                iconLocation = '/assets/certichainCardIcon.png'
                style = {{background: 'linear-gradient(180deg, #FFA9A9 2.77%, #F56161 84.45%, #852121 112.22%)'}}
            />
        </CardRow>

        <CardRow>
            <ProjectCard
                header = 'UX DESIGN & RESEARCH CO-OP'
                title = "CIBC Enterprise Innovation"
                descTitle = "My CIBC Work Term"
                desc = "Led a UX strategy sprint to redefine the design team’s process and methods"

                projectLocation = "/cibc"
                imgLocation = '/assets/cibcCard.png'
                iconLocation = '/assets/cibcCardIcon.png'
                style = {{background: 'linear-gradient(0.37deg, #F9D2D9 0.28%, #F7F7F7 96.58%)'}}
            />
            <ProjectCard
                header = 'CASE COMPETITION - STUDENT CHOICE AWARD'
                title = "EDI Calculator"
                descTitle = "AI-Powered EDI Tool"
                desc = "Designed the prototype for our AI-powered Equity, Diversity and Inclusion Calculator"

                projectLocation = "/edicalc"
                imgLocation = '/assets/ediCard.png'
                iconLocation = '/assets/ediCardIcon.png'
                style = {{background: 'linear-gradient(132.18deg, #FFECB8 0%, #FFB1BD 96.6%)'}}
            />
        </CardRow>

        <div class="h-4 w-full"/>
        <Footer />

        </>

    )
}

export default Home;