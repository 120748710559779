import { Link} from 'react-router-dom';

const IntroScreen = (props) => {
    return (
        <Link to={props.projectLocation}>
        <div class="card-shadow rounded-xl bg-white w-full transition-all duration-150 shadow-lg group">

            {/* Card Top */}
            <div class="rounded-xl" style={props.style}>

                {/* Image */}
                <img class="mx-auto px-12 pb-4 pt-2 w-full max-w-lg" src={props.imgLocation}/>

            </div>

            {/* Card Bottom */}
            <div class="h-34 lg:h-42">
            <div class="py-4 lg:py-5 px-6 lg:px-8">

                {/* Titles */}
                <div class="grid grid-cols-5 w-full">
                    <div class="col-span-4">
                        <h2 class="font-nunito font-semibold grey text-xxs lg:text-xs">{props.header}</h2>
                        <h3 class="font-roboto font-semibold light-black text-xl lg:text-2xl xl:text-3xl">{props.title}</h3>
                    </div>
                    <div class="col-span-1 ">
                        <img class="float-right h-12 lg:h-14" src={props.iconLocation}/>
                    </div>
                </div>

                <div class="grid grid-cols-5 w-full">
                <div class="col-span-4">
                    <div class="pt-3">
                        <h3 class="font-nunito font-extrabold text-xs lg:text-base">{props.descTitle}</h3>
                        <p class="font-nunito font-normal text-xxs lg:text-sm">{props.desc}</p>
                    </div>
                </div>

                <div class="col-span-1 pt-4 flex">
                    <img class="my-auto ml-auto h-4 lg:h-6 group-hover:animate-sideBounce ease-out" src="/assets/rightArrow.svg"/>
                </div>
                </div>

            </div>
            </div>

        </div>
        </Link>
    )
}

export default IntroScreen