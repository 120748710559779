import React from "react"

const CardRow = (props) => {
    let children = React.Children.toArray(props.children)
    return(
        <div class="px-6 md:px-8 py-2.5">
        <div class="container flex flex-wrap items-center mx-auto">
        <div class="w-screen h-12"/>
        <div class="w-full md:w-6/12 pr-0 md:pr-8">
            {children[0]}
        </div>
        <div class="w-full md:w-6/12 pl-0 md:pl-8 pt-16 md:pt-0">
            {children[1]}
        </div>
        </div>
        </div>
    )
}

export default CardRow