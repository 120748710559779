const UnderConstruction = (props) => {
    return (
        <>
        <div class="container flex flex-col flex-wrap items-center mx-auto pt-4 px-4 lg:px-8 pt-24">
            <div class="mx-auto flex flex-col">
                <h1 class="text-3xl md:text-4xl font-roboto font-semibold leading-tight blue-red-gradient text-center">
                    This Page is Under Construction
                </h1>
                <p class="pt-6 text-center self-center w-8/12">
                    This page will be completed soon! If you wish to view some of my work, you can view my PDF portfolio below!
                </p>
            </div>
            <div class="flex">
                <button class="mt-8 mx-4 bg-violet-100 hover:bg-violet-400 text-black hover:text-white font-semibold py-2 px-4 rounded card-shadow">
                    <a href="/assets/portfolio.pdf">View PDF Portfolio</a>
                </button>
                <button class="mt-8 mx-4 hover:bg-black text-grey hover:bg-violet-400 text-black hover:text-white font-semibold py-2 px-4 rounded card-shadow">
                    <a href="/">Home</a>
                </button>
            </div>
        </div>
        </>

    )
}

export default UnderConstruction