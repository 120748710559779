import './App.css'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import ScrollToTop from "./scrollToTop"

import Home from "./pages/Home"
import MyPlayground from "./pages/MyPlayground"
import About from "./pages/About"

import GoodMarket from './pages/Projects/GoodMarket'
import CIBC from './pages/Projects/CIBC'
import EDICalculator from './pages/Projects/EDICalculator'
import CertiChain from './pages/Projects/CertiChain'

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/playground" element={<MyPlayground />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/goodmarket" element={<GoodMarket />}></Route>
      <Route path="/cibc" element={<CIBC />}></Route>
      <Route path="/edicalc" element={<EDICalculator />}></Route>
      <Route path="/certichain" element={<CertiChain />}></Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
