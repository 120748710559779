import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import ProjectHero from "../../components/ProjectHero"

import Line from "../../components/projectComponents/Line"


function CIBC(){
    return (
        <div class="flex flex-col h-screen">

        <ProjectHero
            heroHeader = "Winter 2022 CO-OP INTERNSHIP AT"
            heroTitle = "CIBC Enterprise Innovation"
            heroDesc = "UX Design // January - April 2022"

            imgLocation = "/assets/cibcAppBig.png"
            style = {{background: '#D7E0EE'}}
        />
   
   <div class="px-6 md:px-16 py-2.5">
        <div class="container flex flex-wrap items-center  max-w-screen-md mx-auto">
        <div class="w-screen h-12"/>

        <h1 class="font-bold text-3xl pb-10 w-full">Project Summary</h1>
        <div class="grid grid-cols-2 pb-10 w-full">
            <div class="col-span-1">
                <h3 class="font-bold text-xl pb-2">TIMELINE</h3>
                <p>January - April 2022</p>
            </div>
            <div class="col-span-1">
                <h3 class="font-bold text-xl pb-2">MY ROLE</h3>
                <p>UX Research & Design Intern</p>
            </div>
        </div>

        <h2 class="font-bold text-xl pb-2">Overview</h2>
        <p>CIBC is one of the largest banks in Canada with multiple sectors and lines of business. During this 4 month internship, I had the opportunity to work with design professionals within the technology team known as Enterprise Innovation (EI). Due to confidentiality purposes, the details about my project will be limited on this webpage but feel free to contact me to learn more - I’d be happy to share my experience!</p>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Context</h1>
            <p>The Enterprise Innovation (EI) team is one of the youngest teams in CIBC. It has been around for 6 to 7 years, and it acts as the bank’s technology branch for exploring innovative solutions for their clients. The EI team was looking to re-evaluate their design sector to see if they are up to industry standard - a rediscovery. During my time on the EI team, I led a project to develop a new product strategy to enhance their current design methodologies and project workflows for the team’s future roadmap. </p>
        </div>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">My Process & Methods</h1>
            <div class="grid grid-cols-2 gap-12 w-full">
                <div class="col-span-1">
                    <img src="/assets/cibcPic1.png"></img>
                </div>
                <div class="col-span-1">
                    <img src="/assets/cibcPic2.png"></img>
                </div>
            </div>
        </div>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Outcomes</h1>
            <div class="grid grid-cols-2 gap-12 w-full">
                <div class="col-span-1">
                    <p>Throughout the duration of this project, I orchestrated a total of 18 UX interviews and 5 UX workshops. One of the major outcomes was the development of a UX strategy to which the team can use as a guideline to complete future projects and sprints for their clients. This project  was approved by the Sr. Director and Sr. Managers, which was then implemented across 3 adjacent teams. Doing so also helped break down existing barriers between cross functional teams to be less siloed and more inclusive.</p>
                </div>
                <div class="col-span-1">
                    <img src="/assets/cibcPic3.png"></img>
                </div>
            </div>
        </div>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-8 w-full text-rose-800">Post-project Remarks</h1>
            <img src="/assets/cibcPic4.png"></img>
        </div>

        <h1 class="font-bold text-3xl pb-2 w-full">Future of This Project</h1>
        <p>A few months after my internship, I reached out to one of my co-workers to ask about what came after the project following my departure from the company. I was happy to learn that the final UX strategy recommendation was presented to a Senior Executive Vice-President, to which they were very impressed to see that the team took their time to evaluate themselves on their strengths and weaknesses. Moving forward, the EI team will continue to follow this newly established process as a guide and a way to measure the success of future engagements.</p>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-4 w-full">Reflection</h1>
            <div class="grid grid-cols-2 gap-12 w-full">
                <div class="col-span-1">
                    <h3 class="font-bold text-xl pb-4">Challenged I Faced</h3>
                    <p>Not only was this experience my first UX internship but it was also my first introduction to business and Fintech. The hardest part, especially at the beginning, was getting familiar with the routine, the design thinking mindset, and most importantly, the vocabulary. Stepping into the business world was definitely the most challenging aspect, especially coming from a science background, but it was also the most satisfying to see myself grow as I became more immersed in my role. By the end of my work term, I was throwing acronyms and tech jargon left and right :)</p>
                </div>
                <div class="col-span-1">
                    <h3 class="font-bold text-xl pb-4">What I Learned</h3>
                    <p>Looking back, one of the achievements that I am most proud of, was the growth in my storytelling ability and being able to present information in a clear and concise way. Within the 4 months of my internship, there were many opportunities to practice my public speaking, especially in front of leadership and the CIBC student community. Additionally, I learned from my mentors that it is okay to ask questions even if I feel that they are not relevant. I have the EI team to thank in showing me the value of creating safe spaces in a workplace environment. I hope to take all these learnings and apply them to future experiences!</p>
                </div>
            </div>
        </div>

        </div>
        </div>

        <Footer/>
        
       </div>
    )
}

export default CIBC;