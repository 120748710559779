import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import UnderConstruction from "../components/UnderConstruction";

function Playground(){
    return (
        <div class="flex flex-col h-screen">
        <Navbar/>
   
        <UnderConstruction/>

        <Footer/>
        
       </div>
    )
}

export default Playground;