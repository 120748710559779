import Navbar from "./Navbar"

const IntroScreen = (props) => {
    return (
        <div class="flex flex-col md:h-screen" style={{maxHeight:"850px"}}>

            <Navbar/>

            <div class="container my-auto flex flex-wrap justify-self-center items-center pt-14 mx-auto px-6 lg:px-8 lg:pb-12">

                <div class="my-auto md:pr-2 w-full text-center md:text-left md:w-6/12 pt-12 md:pt-0 md:my-6 order-2 md:order-1 lg:pb-0">
                    <img class="h-12 hidden md:block" src="/assets/intromoon.png"></img>
                    <h1 class=" text-7xl md:text-8xl font-roboto font-semibold leading-tight">
                        <span class="blue-red-gradient">JIELIN</span><br/>
                        <span class="blue-red-gradient">XIA</span>
                    </h1>
                    <h3 class="text-md md:text-lg font-nunito pt-4 font-extrabold">/jee-lin sha/  &nbsp;&nbsp;|&nbsp;&nbsp;  A growing UX designer</h3>
                    <h3 class="text-md md:text-lg font-nunito pt-4">{props.introBlurb}</h3>
                </div>

                <div class="my-auto md:pl-2 w-full md:w-6/12 pt-12 md:pt-0 md:my-6 order-1 md:order-2">
                    <img src="/assets/planet.png"></img>
                </div>

            </div>

            <div class="w-full lg:pb-6 pt-12 order-3">
                    <img class="mx-auto animate-bounce" src="/assets/DownArrow.svg"/>
             </div>
        </div>
    )
}

export default IntroScreen