import Navbar from "../components/Navbar"
import AboutContent from "../components/AboutContent"
import Footer from "../components/Footer"
import { Fragment } from "react";

function About(){
    return (
     <div class="flex flex-col">
     <Navbar/>

     <AboutContent
        imgLocation = "/assets/aboutMainImage.png"

        header = {<Fragment>
                I'm
                <span class="blue-red-gradient"> Jielin Xia</span><br/>
                a growing UX Designer
            </Fragment>}

        body = {<Fragment>
            I am a UX designer interested in exploring the intersection between design, technology and science. Currently, I am a Bachelor’s student at the University of Toronto studying Biotechnology and Studio Arts. 
            <br/><br/>
            I love to approach design problems through the lens of usability and accessibility while keeping my work trendy and fresh. Recently, I have been reading Aaron Walter’s book on Designing for Emotion to learn more about how to make interactions more human.
            <br/><br/>
            Outside of school I love to immerse myself in different forms of art. Nowadays, I like to practice my hand at digital art, and plan to expand into animation and 3D modeling in the near future. If you are interested I would be happy to connect and chat about design so do not hesitate to reach out! 
        </Fragment>}

     />
     <div class="h-24 w-full"/>
     <Footer/>

    </div>
    )
}

export default About;