import Navbar from "./Navbar"

const ProjectHero = (props) => {
    return (
    <div style={props.style}>
    <div class="flex flex-col md:h-screen px-4" style={{maxHeight:"850px"}}>

        <Navbar/>

        <div class="container my-auto flex flex-wrap justify-self-center items-center mx-auto">

            <div class="my-auto flex flex-col text-center md:text-left p-4 md:pr-2 w-full md:w-6/12 pt-12 md:pt-0 md:my-6 order-2 md:order-1 lg:pb-0">
                <h3 class="text-sm md:text-base font-nunito pb-2 lg:pb-0">{props.heroHeader}</h3>
                <h1 class=" text-3xl md:text-6xl font-roboto font-semibold leading-none w-full lg:py-4 lg:w-10/12">{props.heroTitle}</h1>
                <h3 class="text-sm md:text-base font-nunito pt-2">{props.heroDesc}</h3>
            </div>

            <div class="my-auto p-4 md:pl-2 w-full md:w-6/12 pt-12 md:pt-0 md:my-6 order-1 md:order-2">
                <img src={props.imgLocation}></img>
            </div>

        </div>

        <div class="w-full pt-6 pb-12 order-3">
                <img class="mx-auto animate-bounce" src="/assets/DownArrow.svg"/>
            </div>

    </div>
    </div>
    )
}

export default ProjectHero