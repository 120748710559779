import Footer from "../../components/Footer"
import ProjectHero from "../../components/ProjectHero"

import UnderConstruction from "../../components/UnderConstruction";

function EDICalculator(){
    return (
        <div class="flex flex-col h-screen">

        <ProjectHero
            heroHeader = "CASE COMPETITION - STUDENT CHOICE AWARD"
            heroTitle = "EDI Calculator"
            heroDesc = "UX Design // July - August 2021 (8 weeks)"

            imgLocation = "/assets/ediAppBig.png"
            style = {{background: 'linear-gradient(132.18deg, #FFECB8 0%, #FFB1BD 96.6%)'}}
        />

        <UnderConstruction/>

        <Footer/>
        
       </div>
    )
}

export default EDICalculator;