const AboutContent = (props) => {
    return (
        <>
        <div class="container flex flex-wrap h-screen items-center mx-auto pt-4 px-4 lg:px-8 lg:pb-20 lg:pt-20">
            <div class="flex justify-center lg:w-5/12 pr-6 pb-12 lg:pb-0">
                <img class="object-contain w-10/12 lg:w-full" src={props.imgLocation}></img>
            </div>
            <div class="lg:w-7/12 pl-6">
                <h1 class="text-3xl md:text-4xl font-roboto font-semibold leading-tight pb-6">
                    {props.header}
                </h1>
                <p class="text-md md:text-lg font-nunito">
                    {props.body}
                </p>
            </div>
        </div>
        </>

    )
}

export default AboutContent