const Footer = (props) => {
    return(
    <>
    <div class="mt-auto"/>
    <div class="container mx-auto flex justify-center pb-8 md:justify-end px-4 pt-24 lg:pt-32">
        <a class="flex font-nunito font-regular underline lg:text-lg mr-8" href = "mailto: jlxia116@gmail.com">jlxia116@gmail.com</a>
        <a class="flex font-nunito font-regular underline lg:text-lg" href="https://www.linkedin.com/in/jielinx1/">LinkedIn</a>
    </div>
    </>
    )
}

export default Footer