import Footer from "../../components/Footer"
import ProjectHero from "../../components/ProjectHero"

import Line from "../../components/projectComponents/Line"

function CertiChain(){
    return (
        <div class="flex flex-col h-screen">

        <ProjectHero
            heroHeader = "HACKATHON - PROJECT WINNER"
            heroTitle = "Certi-Chain"
            heroDesc = "UX Design // November 2020 (3 Days)"

            imgLocation = "/assets/certichainAppBig.png"
            style = {{background: '#F56161'}}
        />

        <div class="px-6 md:px-16 py-2.5">
        <div class="container flex flex-wrap items-center  max-w-screen-md mx-auto">
        <div class="w-screen h-12"/>

        <h1 class="font-bold text-3xl pb-10 w-full">Project Summary</h1>
        <div class="grid grid-cols-2 pb-10 w-full">
            <div class="col-span-1">
                <h3 class="font-bold text-xl pb-2">TIMELINE</h3>
                <p>November 2020 (3 Days)</p>
            </div>
            <div class="col-span-1">
                <h3 class="font-bold text-xl pb-2">MY ROLE</h3>
                <p>UI Designer</p>
            </div>
        </div>

        <h2 class="font-bold text-xl pb-2">OUTCOMES</h2>
        <ul class="list-disc list-outside pl-5">
            <li>Designed and prototyped the key pages for a product authenticator web app. Check out our hackathon project <a class="underline text-rose-600" href="https://devpost.com/software/certi-chain">HERE</a>. Special shoutout to Johnson Su, Ben Saobuppha, and Vincent Li for being such a great team!</li>
            <li>This project won the Best Hardware award sponsored by Digi-Key for the Hack Western 7, 2020</li>
        </ul>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Background</h1>
            <p>Each year, businesses from all over the world suffer from the counterfeiting industry. With fake goods flooding the market, producers are forced into bankruptcy and consumers no longer know what is real or fake. This is especially relevant to luxury items such as designer bags and clothing pieces. Even the manufacture of many common food products, such as the olive oil industry, is discovered to be 80% fraudulent, as poor quality olive oil is deliberately mislabeled as virgin or extra virgin. This also severely impacts regions of the world that rely on the production of their speciality goods (i.e. Kobe beef from Kansai, Japan).</p>
        </div>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Our Objective</h1>
            <p>We want to provide a service that is both economical and accurate in determining the authenticity of goods and to prevent the widespread of counterfeited products. By understanding the characteristics of blockchain and NFC tags, we want to implement unique ID tags in a decentralized system. This prevents unecessary duplication and counterfeiting.</p>
        </div>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Certi-Chain</h1>
            <h3 class="font-bold text-xl pb-8 w-full">How it works</h3>
            <div class="grid grid-cols-3 gap-8 w-full">

                <div class="col-span-1">
                    <img src="/assets/certichainPic1.png"></img>
                    <p class="pt-4 text-center">Phone taps against NFC tag attached to the product; popup webpage appears.</p>
                </div>

                <div class="col-span-1">
                    <img src="/assets/certichainPic2.png"></img>
                    <p class="pt-4 text-center">Webpage directs user to the Certi-Chain authentication page. If authentic the page will reveal product origins and history.</p>
                </div>

                <div class="col-span-1">
                    <img src="/assets/certichainPic3.png"></img>
                    <p class="pt-4 text-center">If item that is not registered with Certi-Chain, the webpage will reveal “Not Authentic”.</p>
                </div>
                
            </div>
        </div>

            <h1 class="font-bold text-3xl pb-2 w-full text-center">Final Product</h1>
            <img src="/assets/certichainGif1.gif"></img>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">The Design</h1>
            <p>The design needs for this project were quite simple. It involved two main webpages: “Authentic” and “Not Authentic”. However, it was evident from my first iteration that it was not the best suited design for Certi Chain. After re-evaluating, I realized that the “fun” and colourful appearance does not adhere to the purpose of this project. This webpage should be versatile and minimal to suit the brands that may use Certi-Chain as their authenticator. Thus, the UI should aim to be more simplified, clean, and neutral to align the goals of both Certi-Chain and partnered brands.</p>     
        </div>
        
        <div class="grid grid-cols-2 w-full gap-12">

            <div class="col-span-1">
                <h2 class="font-bold text-xl pb-2">Before</h2>
                <img src="/assets/certichainPic4.png"></img>
            </div>

            <div class="col-span-1">
                <h2 class="font-bold text-xl pb-2">After</h2>
                <img src="/assets/certichainPic5.png"></img>
            </div>
        </div>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Reflection</h1>
            <h3 class="font-bold text-xl pb-8 w-full">What I learned</h3>
            <p>From a designer's perspective, this experience helped me understand the importance of the client's requests and criteria. In this case, understanding the expectations and criteria of my team was what allowed me to develop the final design. In my first attempt, rather than considering the perspective and purpose of Certi-Chain, I was too immersed in my own style. After reconsidering the main purpose of Certi-Chain, it became clear to me how the webpage should be designed. In addition to the UI, it is also important to consider the information architecture, site map, and usability heuristics of the website. From this experience I realized that there is way more to think about than what meets the eye.</p>
        </div>

        </div>
        </div>

        <Footer/>
        
       </div>
    )
}

export default CertiChain;