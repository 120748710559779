import Footer from "../../components/Footer"
import ProjectHero from "../../components/ProjectHero"

import Line from "../../components/projectComponents/Line"

function GoodMarket(){
    return (
        <div class="flex flex-col h-screen">

        <ProjectHero
            heroHeader = "DESIGNLAB PROJECT"
            heroTitle = "GoodMarket"
            heroDesc = "Online Grocery Shopping App"

            imgLocation = "/assets/goodmarketAppBig.png"
            style = {{background: 'linear-gradient(130.97deg, rgba(208, 142, 255, 0.3192) 0.13%, rgba(255, 204, 102, 0.3192) 83.83%)'}}
        />

<div class="px-6 md:px-16 py-2.5">
        <div class="container flex flex-wrap items-center  max-w-screen-md mx-auto">
        <div class="w-screen h-12"/>

        <h1 class="font-bold text-3xl pb-10 w-full">Project Summary</h1>
        <div class="grid grid-cols-2 pb-10 w-full">
            <div class="col-span-1">
                <h3 class="font-bold text-xl pb-2">TIMELINE</h3>
                <p>July - August 2021 (8 weeks)</p>
            </div>
            <div class="col-span-1">
                <h3 class="font-bold text-xl pb-2">MY ROLE</h3>
                <p>Solo project for the DesignLab UX Academy Bootcamp</p>
            </div>
        </div>

        <h2 class="font-bold text-xl pb-2">OUTCOMES</h2>
        <ul class="list-disc list-outside pl-5">
            <li>A medium-fidelity prototype as an extension of this Bootcamp project. Check it out <a class="underline text-orange-600" href="https://www.figma.com/proto/1z8JE3UYxJrA0BhCdLOAoo/GoodMarket-Artifacts?node-id=1204%3A272&scaling=scale-down&page-id=1201%3A271&starting-point-node-id=1204%3A272">HERE</a> or keep scrolling</li>
            <li>A user flow and information architecture for a first-time user and customer journey</li>
            <li>Researched and developed designs that emphasize convenience and flexibility for the user in their online shopping experience</li>
        </ul>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Background</h1>
            <p>Researched and developed designs that emphasize convenience and flexibility for the user in their online shopping experience</p>
        </div>

        <div class="pb-10">
            <div class="grid grid-cols-2 gap-12 w-full">
                <div class="col-span-1">
                    <h1 class="font-bold text-3xl pb-2 w-full">Problem</h1>
                    <h3 class="font-bold text-xl pb-2 w-full">So what?</h3>
                    <p>Over the past few years, their market share value has been decreasing by 8% each year and they are desperately looking for ways to improve these numbers. After some research, GoodMarket realized that the reason for their decline is due to the rising number of online grocery shopping products and services. Many customers prefer the flexibility and convenience of online grocery shopping over in-store.</p>
                </div>
                <div class="col-span-1">
                    <h1 class="font-bold text-3xl pb-2 w-full">Solution</h1>
                    <h3 class="font-bold text-xl pb-2 w-full">What can we do?</h3>
                    <p>GoodMarket customers should have the option to shop online, wherever and whenever. Offering the same shopping experience through a mobile app will give shoppers flexibility, and expand GoodMarket’s customer base and market share. </p>
                </div>
            </div>
        </div>

        <h1 class="text-xl text-center font-bold italic pb-6 uppercase">"In the U.S., online grocery shopping reached nearly <span class="text-orange-500">$90 billion in sales </span> in 2020, an increase of <span class="text-orange-500">more than $30 billion.</span>"</h1>
        <div class="w-full">
            <p class="text-center text-xs"><a href="https://www.ncr.com/blogs/retail/grocery-shoppers-going-digital" class="underline">NCR Corporation</a>, 2021</p>
        </div>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Competitive Analysis</h1>
            <p>Before diving in, I compared 3 popular mobile apps surrounding the online grocery shopping experience, each incorporating their own set of unique features and functions. Each mobile app is analyzed for its UI design, business logic & services, system features and usability heuristics.</p>
        </div>
        <div class="grid grid-cols-3 gap-12 w-full px-12 pb-10">
            <div class="col-span-1">
                <img class="drop-shadow	" src="/assets/goodmarketPic1.png"></img>
                <h3 class="text-center font-bold">Instacart</h3>
            </div>
            <div class="col-span-1">
                <img class="drop-shadow	" src="/assets/goodmarketPic2.png"></img>
                <h3 class="text-center font-bold">PC Express</h3>
            </div>
            <div class="col-span-1">
                <img class="drop-shadow	" src="/assets/goodmarketPic3.png"></img>
                <h3 class="text-center font-bold">Walmart</h3>
            </div>
        </div>
        <div class="pb-10">
            <p>Overall, each mobile app is visually appealing, easy to navigate, and includes features that ensure similarities to in-store shopping. Out of the three, I found Instacart to be the most well-rounded in all aspects. They also include a Guest feature which I find very convenient for the “grab and go” type shoppers.</p>
        </div>

        <h1 class="font-bold text-3xl pb-2 w-full">Target Persona</h1>
        <div class="pb-10">
            <div class="grid grid-cols-2 gap-12 w-full">
                <div class="col-span-1">
                    <p class="pb-4">Meet Sofia, a 26 year-old Business Analyst working at a reputable company in Toronto. Her work hours are typically 9 AM to 7 PM and she frequently goes out after work. Due to her schedule, <span class="font-bold">she has limited time to buy groceries during the week.</span> She is very tech savvy and likes to spend time alone in her cozy apartment on the weekends. </p>

                    <p class="font-bold">Goals</p>
                    <ul class="list-disc list-outside pl-5 pb-4">
                        <li>Wants to be able to save money and cook her own meals instead of dining or getting take-out</li>
                    </ul>

                    <p class="font-bold">Needs</p>
                    <ul class="list-disc list-outside pl-5 pb-4">
                        <li>She needs an app that is accessible anywhere and anytime (e.g. between breaks at work)</li>
                        <li>Wants to maintain a healthy diet and sustainable lifestyle</li>
                    </ul>

                    <p class="font-bold">Pain Points</p>
                    <ul class="list-disc list-outside pl-5 pb-4">
                        <li>Can't find time to make grocery run</li>
                        <li>Doesn’t like waiting in line and long payment transactions</li>
                    </ul>
                </div>
                <div class="col-span-1">
                    <img src="/assets/goodmarketPic4.png"></img>
                    <p>Image from <a href="https://www.freepik.com/" class="underline">Freepik</a></p>
                </div>
            </div>
        </div>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Business Goals</h1>
            <p>Now that I have a solid understanding of GoodMarket and the target persona, I constructed a visual comparison of both the business goals and pain points. Placing these side-by-side allowed me to deduct the overlapping shared goals. Ultimately, the goal of this project is to develop a mobile app that can bring about a convenient and automated shopping experience for its customers, which in turn would satisfy the GoodMarket business goals.</p>
        </div>

        <div class="grid grid-cols-2 gap-12 w-full px-12 pb-10">
            <div class="col-span-1">
                <img class="drop-shadow	" src="/assets/goodmarketPic5.png"></img>
            </div>
            <div class="col-span-1">
                <img class="drop-shadow	" src="/assets/goodmarketPic6.png"></img>
            </div>
        </div>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Card Sorting</h1>
            <h3 class="font-bold text-xl pb-8 w-full">Uncovering Mental Models</h3>
            <div class="grid grid-cols-2 gap-12 w-full">
                <div class="col-span-1">
                    <p>Six participants, between the ages of 20-40 were asked to participate in a card sorting exercise to visualize different mental models. The exercise involves grouping 20 food items into distinct grocery categories. <br/><br/> Some common categories that the 6 participants used include Dairy, Produce, and Bakery. When asked what they found the most challenging, most of them found it difficult to categorize certain items such as soy milk or almonds.</p> 
                </div>
                <div class="col-span-1">
                    <img src="/assets/goodmarketPic7.png"></img>
                </div>
            </div>
        </div>

        <Line/>

        <div class="pb-10">
            <h1 class="font-bold text-3xl pb-2 w-full">Design Choices</h1>
            <p>After studying the market research and interacting with my participants, I moved on to the next steps: understanding information architecture, wireframing, and finally, prototyping. <br/><br/> With some brainstorming, editing and feedback on my design iterations, I was able to develop the final prototype.</p>     
        </div>
        
        <div class="grid grid-cols-3 gap-12 w-full pb-10">
            <div class="col-span-2">
                <img src="/assets/goodmarketPic8.png"></img>
            </div>
            <div class="col-span-1 flex flex-col justify-center">
                <h2 class="text-xl pb-4 text-orange-400 font-bold">Homepage & Browsing</h2>
                <ul class="list-disc list-outside pl-5 pb-4">
                    <li>Shop with ease with all the featured items and categories (i.e. Weekly deals, Cart Starter Items, Breakfast Foods, etc).</li>
                    <li>Refine searches with the filter tool</li>
                </ul>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-12 w-full pb-10">
            <div class="col-span-1 flex flex-col justify-center">
                <h2 class="text-xl pb-4 text-orange-400 font-bold">Add to Cart</h2>
                <ul class="list-disc list-outside pl-5 pb-4">
                    <li>Save your favorite food items by “hearting”</li>
                    <li>Edit your cart preferences by adding instructions, removing, or changing the item</li>
                </ul>
            </div>
            <div class="col-span-2">
                <img src="/assets/goodmarketPic9.png"></img>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-12 w-full pb">
            <div class="col-span-2">
                <img src="/assets/goodmarketPic10.png"></img>
            </div>
            <div class="col-span-1 flex flex-col justify-center">
                <h2 class="text-xl pb-4 text-orange-400 font-bold">Sign up & Checkout</h2>
                <ul class="list-disc list-outside pl-5 pb-4">
                    <li>Save payment and delivery information</li>
                    <li>Earn redeemable points with each purchase</li>
                    <li>Option to track progress of the order or cancelling it</li>
                </ul>
            </div>
        </div>

        <Line/>

        <h1 class="font-bold text-3xl pb-8 w-full">Final Product</h1>

        <h3 class="font-bold text-xl pb-2 w-full">New User Flow Screens</h3>
        <iframe class="w-full aspect-video pb-10" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F1z8JE3UYxJrA0BhCdLOAoo%2FGoodMarket-Artifacts%3Fpage-id%3D1201%253A271%26node-id%3D1201%253A271%26scaling%3Dscale-down%26starting-point-node-id%3D1204%253A272" allowFullScreen></iframe>

        <h3 class="font-bold text-xl pb-2 w-full">New User Flow Overview</h3>
        <iframe class="w-full aspect-video pb-10" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F1z8JE3UYxJrA0BhCdLOAoo%2FGoodMarket-Artifacts%3Fnode-id%3D1201%253A271%26t%3DD9AThFLW7e78jrB0-1" allowFullScreen></iframe>

        </div>
        </div>

        <Footer/>
        
       </div>
    )
}

export default GoodMarket;